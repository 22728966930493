<template>
  <div>
    <template v-if="bionic">
<!--      This bionic nav popover item works slightly differently than the other bionic nav popover items
       due to the interaction needed with the autocomplete. -->
      <p-popover ref="customerSelectorPopover"
                 class="customer-selector"
                 @show="popoverShowSideEffects">
        <div style="width:250px;">
          <div>
            <span>Customer:</span> <h5 class="d-inline">{{selectedCustomer}}</h5>
            <div class="d-flex my-2">
              <p-icon-field class="w-100">
                <p-input-icon><fluency-icon type="find"/></p-input-icon>
                <p-input-text class="w-100 p-autocomplete-input" ref="customerFilterInput" v-model="filterInput" placeholder="Filter" @keyup="pvAutoComplete_complete($event)"/>
              </p-icon-field>
              <b-button @click="reloadDropdown()" variant="ghost-light"><fluency-icon type="loop"/></b-button>
            </div>
            <div style="max-height:60vh;overflow-y:scroll;">
              <ul class="list-unstyled p-0 m-0">
                <li
                  v-for="customer in sortedFilteredCustomerList"
                  :key="customer.id"
                  class="my-2 mr-2 pl-2 pr-4 py-3 hover-highlight cursor-pointer"
                  :class="{
                    'border-primary border-2': customer.value === selectedActingAsUser,
                    'border-gray-300': customer.value !== selectedActingAsUser
                  }"
                  :aria-label="customer.text"
                  tabindex="0"
                  style=""
                  @keyup.enter="switchCustomer(customer.value)"
                  @click="switchCustomer(customer.value)"
                  @contextmenu="handleContextMenu($event, customer)"
                >
                  <div aria-hidden="true" class="d-flex" style="">
                    <img width="16" height="16" :src="getFavIconUrl(customer.value)"/>
                    <div class="ml-4">{{customer.text}}</div>
                    <span :class="{
                      'text-muted': isFavorite(customer.value),
                      'd-none': !isFavorite(customer.value)
                    }"> *</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </p-popover>

      <div class="d-flex-center-center" v-if="selectOptions.length > 0">
        <!-- @mouseenter="showNavWithDelay($event, navPopoverId)" removed mouse enter for internal users due to people demoing and hovering over our client list - SNG -->
        <div tabindex="0"
             ref="customerSelectorTarget"
             @mouseenter="(isInternalUser) ? void(0) : showNavWithDelay($event, navPopoverId)"
             @mouseleave="cancelShowIfUnopened(navPopoverId)"
             @click.stop="showNavWithDelay($event, navPopoverId, 0)">
          <div class="bionic-customer-switcher-circle cursor-pointer d-flex-center-center">
            {{bionicNavBtnText}}
          </div>
        </div>
      </div>

    </template>
    <template v-else>
      <div v-if="selectOptions.length > 0">
        <div class="d-flex">
          <basic-mad-lib-input :value="selectedActingAsUser"
                               class="userCustomerSelector"
                               data-qa="userCustomerSelector"
                               :type-ahead-options="selectOptions"
                               placeholder="Select Customer"
                               :retain-value-on-focus="false"
                               text-variant="info font-weight-normal"
                               require-option
                               :pagingSize="100"
                               @input="switchCustomer($event)" />
          <t-button @click="reloadDropdown()" size="sm" variant="ghost-secondary"><fluency-icon type="loop" /></t-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useBionicNavUtils } from '@/composables/useBionicNavUtils.js'

const { addNavPopover, repositionRight, showNavWithDelay, cancelShowIfUnopened } = useBionicNavUtils()

const customerSelectorPopover = ref(null)
const customerSelectorTarget = ref(null)
const customerFilterInput = ref(null)
const navPopoverId = 'userCustomerSelector'

onMounted(() => {
  addNavPopover({
    navId: navPopoverId,
    targetEl: customerSelectorTarget.value,
    popoverRef: customerSelectorPopover.value
  })
})

const popoverShowSideEffects = async () => {
  repositionRight(customerSelectorPopover.value, { bottom: '195px' })
  setTimeout(() => {
    customerFilterInput.value?.$el?.focus()
  }, 100)
}

</script>

<script>
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import _intersection from 'lodash.intersection'
import _debounce from 'lodash.debounce'

export default {
  name: 'userCustomerSelector',
  components: { BasicMadLibInput },
  props: {
    actingAsUser: {
      type: Number
    },
    switchImmediately: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'sm'
    },
    dropDown: {
      type: Boolean,
      default: false
    },
    bionic: {
      type: Boolean,
      default: false
    },
    popover: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      primeVueInput: null,
      filteredCustomerList: null,
      bionicDropdownReady: true,
      customerLogoMap: {},
      filterInput: ''
    }
  },
  async mounted () {
    if (this.selectedActingAsUser) {
      this.primeVueInput = this.selectOptions.find(customer => customer.value === this.selectedActingAsUser)
    }
    await this.getFavicons()
    this.filteredCustomerList = [...this.selectOptions]
    // this.delayedDebugger()
  },
  watch: {
    primeVueInput: {
      handler (newVal) {
        if (newVal && newVal.hasOwnProperty('value') && newVal?.value !== this.selectedActingAsUser) {
          this.switchCustomerPv(newVal)
        }

        if (newVal === null) {
          this.resetPrimeVueInputToCurrent()
        }
      }
    }
  },
  computed: {
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    selectedCustomer () {
      const selectedOption = this.selectOptions.find(opt => opt.value === this.selectedActingAsUser)
      return selectedOption ? selectedOption.text : 'Default'
    },
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    selectedActingAsUser () {
      return this.actingAsUser || this.$store.getters.actingAsUser || null
    },
    selectOptions () {
      let options = []
      if (this.user?.allowableCustomers && this.user?.allowableCustomers.length > 0) {
        if (this.isInternalUser) {
          options = [
            {
              text: 'Default', // (${this.user.allowableCustomers.find(opt => opt.customerId === this.user.customerId).name})`,
              value: '-1',
              logo: null
            }
          ]
        }

        for (let i = 0; i < this.user.allowableCustomers.length; i++) {
          const c = this.user.allowableCustomers[i]
          options.push({
            text: c.name,
            value: c.customerId,
            logo: this.customerLogoMap[c.customerId]
          })
        }
      }
      return options
    },
    bionicNavBtnText () {
      if (this.selectedActingAsUser) {
        const customer = this.selectOptions.find(o => o.value === this.selectedActingAsUser)

        if (customer && customer.text) {
          return customer.text.substr(0, 1)
        }
      }

      return '-'
    },
    sortedFilteredCustomerList () {
      const favoriteCustomers = this.$store.getters.favoriteCustomers || []

      // Separate favorites and non-favorites
      const favorites = this.filteredCustomerList.filter(customer =>
        favoriteCustomers.includes(customer.value)
      )

      const nonFavorites = this.filteredCustomerList.filter(customer =>
        !favoriteCustomers.includes(customer.value)
      )

      // Combine favorites first, then non-favorites
      return [...favorites, ...nonFavorites]
    }
  },
  methods: {
    handleContextMenu (event, customer) {
      if (this.isInternalUser) {
        event.preventDefault()
        this.toggleFavorite(customer)
      }
      // If not an internal user, the default context menu will show
    },
    async getFavicons () {
      if (!this.user.allowableCustomerFavIcons) {
        const response = await this.$res.customer.getFavicons()

        if (response) {
          this.$setCompat(this, 'customerLogoMap', response)
          const newUser = {
            ...this.user,
            allowableCustomerFavIcons: response
          }
          this.$store.commit('user', newUser)
        }
      } else {
        this.$setCompat(this, 'customerLogoMap', this.user.allowableCustomerFavIcons)
      }
      /*
      const response2 = await this.$res.customer.insights()
      const response2 = await this.$res.customer.insights()
      if (response2) {
        response2.forEach((insight) => {
          insight.isFlipped = false
        })
        const customerLogoMap = response2.reduce((map, item) => {
          map[item.customer.customerId] = item.logo || ''
          return map
        }, {})
        console.log('customer logo map', customerLogoMap)
        this.$setCompat(this, 'customerLogoMap', customerLogoMap)
      }
       */
    },
    popoverShown () {
      setTimeout(() => {
        this.bionicDropdownReady = true
      }, 1250)
    },
    popoverHidden () {
      this.bionicDropdownReady = false
    },
    resetPrimeVueInputToCurrent () {
      const currentSelectOption = this.selectOptions.find(opt => opt.value === this.selectedActingAsUser)

      if (currentSelectOption) {
        this.primeVueInput = currentSelectOption
      }
    },
    pvAutoComplete_complete: _debounce(function (event) {
      console.log(event, 'event', this.$refs.filterInput)
      const filterText = this.filterInput.trim()
      if (!filterText.length) {
        this.filteredCustomerList = [...this.selectOptions]
      } else {
        this.filteredCustomerList = this.selectOptions.filter(c =>
          // null and undefined checks because we had some blank customers come in here and break the filtering - SNG
          c &&
          c.text &&
          (c.text.toLowerCase().includes(filterText.toLowerCase()) || c.value === '')
        )
      }
    }, 300), // 300ms delay,
    blurHandlerPv (e) {
      const intersection = _intersection(['p-autocomplete-dropdown', 'customer-switcher-reload-btn'], e?.relatedTarget?.classList)
      if (e?.relatedTarget?.className && intersection.length > 0) {
        return
      }
      setTimeout(() => {
        this.emitClosePopover()
      }, 250)
    },
    emitClosePopover () {
      setTimeout(() => {
        this.$root.$emit('bv::hide::popover', this.popoverId)
      }, 100)
    },
    // delayedDebugger () {
    //   setTimeout(function () { debugger }, 10000)
    // },
    async reloadDropdown () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          allowableCustomers: response.allowableCustomers
        }
        this.$store.commit('user', newUser)
        this.$toast('Customer Dropdown Reloaded', 'success')
      }
    },
    switchCustomerPv (val) {
      let url = null

      // if we are drilled into a plan in Manage we want to just send back to Manage landing page
      // and some special handling to make sure we retain the `fsEnv` url param
      if (this.$route.path.startsWith('/manage/')) {
        url = '/manage/'

        const envKey = 'fsEnv'

        if (this.$route?.query?.[envKey]) {
          url = `${url}?fsEnv=${this.$route.query[envKey]}`
        }
      }

      this.switchCustomer(val.value, true, url)
    },
    async switchCustomer (val, performSwitch = false, url) {
      this.$emit('change', val)
      if (this.switchImmediately || performSwitch) {
        this.$store.commit('setActingAsUser', val)
        await this.$store.dispatch('clearCustomerSpecificLocalStore')

        // if we are drilled into a plan in Manage we want to just send back to Manage landing page
        // and some special handling to make sure we retain the `fsEnv` url param
        const manageUrl = '/manage/'
        if (this.$route.path.startsWith(manageUrl)) {
          const envKey = 'fsEnv'
          url = manageUrl

          if (this.$route?.query?.[envKey]) {
            url = `${url}?fsEnv=${this.$route.query[envKey]}`
          }
        }

        if (url) {
          window.location.replace(url)
        } else {
          window.location.reload()
        }
      }
    },
    // Function to get favicon URL, defaulting to customer 2 for unhandled cases
    getFavIconUrl (customerId) {
      // If the customer ID is in the map, return its specific favicon
      if (this.customerLogoMap[customerId]) {
        return this.customerLogoMap[customerId]
      }

      // For any other unhandled URLs, also use the default (customer 2)
      return 'https://fluent-asset.s3.us-east-2.amazonaws.com/customer-favicon/logo-2.png'
    },
    isFavorite (customerId) {
      return this.$store.getters.favoriteCustomers?.includes(customerId)
    },
    toggleFavorite (customer) {
      // debugger
      const action = this.isFavorite(customer.value) ? 'remove' : 'add'
      this.$store.commit('favoriteCustomers', {
        action,
        customerId: customer.value
      })
    }
  }
}
</script>

<style scoped>
.bionic-customer-switcher {
  height: 48px;
}
.bionic-customer-switcher-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #596980;
  background-color: #dee5ee;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.5rem;
}
</style>
<style>
.p-popover.customer-selector {
  height:70vh !important;
  overflow-y:hidden !important;

  .p-popover-content {
    margin-right:0;
    padding-right:0;
  }
}
</style>
